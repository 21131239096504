import axios from 'axios';
import {API_BASE_URL} from "../env";

const api = axios.create({
    baseURL: API_BASE_URL + '/api/whatsapp',
});

export const getTemplates = async (socialProfileId) => {
    const response = await api.get(`/templates/${socialProfileId}`);
    console.log(response)
    return response.data;
};

export const getChatHistory = async (id) => {
    try {
        const response = await api.get(`/chat/${id}`);
        console.log(response.data);
        return response.data || [];
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // Se il server restituisce 404, consideriamolo come una chat vuota
            console.log('Nessuna cronologia chat trovata per questo numero');
            return [];
        }
        // Per altri errori, propaghiamo l'errore
        throw error;
    }
};

export const createTemplate = async (templateData) => {
    const response = await api.post('/create-template', templateData);
    return response.data;
};

export const sendMessage = async (messageData) => {
    const response = await api.post('/send-message', messageData);
    return response.data;
};

export const sendMessageWithAttachment = async (formData) => {

    const response = await api.post('/send-message-with-attachment', formData);
    return response.data;
};

export const updateMessageAsRead = async (chatId, messageId) => {
    try {
        const messageData = { chatId, messageId };
        const response = await api.post('/update-message-read', messageData);
        return response.data;
    } catch (error) {
        console.error('Errore nell\'aggiornamento dello stato del messaggio:', error);
        throw error; // Rilanciamo l'errore per permettere al chiamante di gestirlo se necessario
    }
};

export const archiveChat = async (phoneNumber) => {
    const response = await api.post(`/archive-chat/${phoneNumber}`);
    return response.data;
};

export const getAllChats = async (phoneNumber) => {
    try {
        const response = await api.get(`/GetAllChats/${phoneNumber}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all chats:', error);
        throw error;
    }
};

export const getChatById = async (chatId) => {
    try {
        const response = await api.get(`chat/id/${chatId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching chat by ID:', error);
        throw error;
    }
};


export default api;