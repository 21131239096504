import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import NotFound from './NotFound';
import ChatHistory from './components/ChatHistory';

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/templates" replace />} />
                <Route path="/templates" element={<App />} />
                <Route path="/templates/:phoneNumber" element={<App />} />
                <Route path="/chat/:phoneNumber" element={<App />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route path="/chat-history/:chatId" element={<ChatHistory />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;