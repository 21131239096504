import * as signalR from "@microsoft/signalr";
import {API_BASE_URL} from "../env";

let connection = null;
let connectionPromise = null;

export const initializeSignalRConnection = () => {
    if (connectionPromise) {
        return connectionPromise;
    }

    connectionPromise = new Promise((resolve, reject) => {
        console.log(`Attempting to connect to ${API_BASE_URL}/chatHub`);

        connection = new signalR.HubConnectionBuilder()
            .withUrl(`${API_BASE_URL}/chatHub`)
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect([0, 2000, 10000, 30000]) // Retry delays
            .build();

        connection.onreconnecting((error) => {
            console.log('SignalR is reconnecting due to error:', error);
        });

        connection.onreconnected((connectionId) => {
            console.log('SignalR has reconnected with connection ID:', connectionId);
        });

        connection.onclose((error) => {
            console.log('SignalR connection closed due to error:', error);
        });

        const startConnection = () => {
            connection.start()
                .then(() => {
                    console.log("SignalR connection established successfully");
                    setupConnectionTest();
                    resolve(connection);
                })
                .catch(err => {
                    console.error("Error establishing SignalR connection:", err);
                    console.log("Retrying connection in 5 seconds...");
                    setTimeout(startConnection, 5000);
                });
        };

        startConnection();

        // Set a timeout to reject the promise if connection takes too long
        setTimeout(() => {
            if (connection.state === signalR.HubConnectionState.Disconnected) {
                console.error("SignalR connection timed out");
                reject(new Error("Connection timed out"));
            }
        }, 30000); // 30 seconds timeout
    });

    return connectionPromise;
};

export const getConnection = () => {
    if (!connectionPromise) {
        return initializeSignalRConnection();
    }
    return connectionPromise;
};

export const joinChat = async (chatId) => {
    console.log("Attempting to join chat:", chatId);
    try {
        const conn = await getConnection();
        await conn.invoke("JoinChat", chatId.toString());
        console.log("Successfully joined chat:", chatId);

        // Aggiungi un listener per l'evento "UserJoined"
        conn.on("UserJoined", (message) => {
            console.log("User joined chat:", message);
        });
    } catch (error) {
        console.error("Error joining chat:", error);
        if (error instanceof Error) {
            console.error("Error details:", error.message);
        }
    }
};

export const leaveChat = async (chatId) => {
    console.log("Attempting to leave chat:", chatId);
    try {
        const conn = await getConnection();
        await conn.invoke("LeaveChat", chatId);
        console.log("Successfully left chat:", chatId);
    } catch (error) {
        console.error("Error leaving chat:", error);
    }
};

export const sendMessage = async (chatId, message) => {
    console.log("Attempting to send message to chat:", chatId);
    try {
        const conn = await getConnection();
        await conn.invoke("SendMessage", chatId.toString(), message);
        console.log("Successfully sent message to chat:", chatId);
    } catch (error) {
        console.error("Error sending message:", error);
    }
};

export const addSignalRListener = async (eventName, callback) => {
    console.log("Adding SignalR listener for event:", eventName);
    try {
        const conn = await getConnection();
        // Rimuovi eventuali listener esistenti per evitare duplicati
        conn.off(eventName);
        conn.on(eventName, callback);
        console.log("SignalR listener added successfully for event:", eventName);
    } catch (error) {
        console.error("Error adding SignalR listener:", error);
    }
};

export const removeSignalRListener = async (eventName, callback) => {
    console.log("Removing SignalR listener for event:", eventName);
    try {
        const conn = await getConnection();
        conn.off(eventName, callback);
        console.log("SignalR listener removed successfully for event:", eventName);
    } catch (error) {
        console.error("Error removing SignalR listener:", error);
    }
};

export const setupConnectionTest = async () => {
    try {
        const conn = await getConnection();
        conn.on("ConnectionTest", (message) => {
            console.log("Connection test result:", message);
        });
        await conn.invoke("TestConnection");
    } catch (error) {
        console.error("Error setting up connection test:", error);
    }
};