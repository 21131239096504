import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold mb-4">404 - Pagina non trovata</h1>
            <p className="text-xl mb-8">La pagina che stai cercando non esiste.</p>
            <Link to="/" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Torna alla home
            </Link>
        </div>
    );
};

export default NotFound;