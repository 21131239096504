import React, { useState } from 'react';
import { createTemplate } from '../services/api';

const CreateTemplateModal = ({ isOpen, onClose }) => {
    const [templateData, setTemplateData] = useState({
        elementName: '',
        languageCode: '',
        category: '',
        headerType: '',
        vertical: '',
        body: '',
        headerText: '',
        bodyFooter: '',
        example: ''
    });

    const handleChange = (e) => {
        setTemplateData({ ...templateData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createTemplate(templateData);
            onClose();
            // Qui potresti aggiungere un feedback di successo
        } catch (error) {
            console.error('Errore nella creazione del template:', error);
            // Qui potresti aggiungere un feedback di errore
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Crea un nuovo template</h3>
                <form onSubmit={handleSubmit}>
                    {/* Aggiungi i campi del form qui */}
                    <input
                        type="text"
                        name="elementName"
                        value={templateData.elementName}
                        onChange={handleChange}
                        placeholder="Nome del template"
                        className="mb-2 w-full px-3 py-2 border rounded-md"
                    />
                    {/* Aggiungi gli altri campi in modo simile */}
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="px-4 py-2 bg-magenta-500 text-white rounded-md hover:bg-magenta-600"
                        >
                            Crea Template
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                        >
                            Annulla
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTemplateModal;