import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { getChatById } from '../services/api';

const ChatHistoryInterface = () => {
    const [messages, setMessages] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const messagesEndRef = useRef(null);
    const { chatId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadChat = async () => {
            try {
                const chatData = await getChatById(chatId);
                setMessages(chatData.messages);
                setPhoneNumber(chatData.phoneNumber);
            } catch (error) {
                console.error('Errore nel caricamento della chat:', error);
                // Gestisci l'errore (ad esempio, reindirizza alla pagina principale)
            }
        };

        loadChat();
    }, [chatId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "d MMMM yyyy, HH:mm", { locale: it });
    };

    const filteredMessages = messages.filter(message =>
        message.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleBack = () => {
        navigate('/templates'); // O qualsiasi altra pagina appropriata
    };

    return (
        <div className="flex flex-col h-screen bg-[#efeae2]">
            {/* Header */}
            <div className="bg-[#f0f2f5] py-2 px-4 flex items-center">
                <button onClick={handleBack} className="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>
                <div className="w-10 h-10 bg-gray-300 rounded-full mr-3 flex-shrink-0"></div>
                <div className="flex-grow">
                    <h2 className="font-semibold text-gray-800">Cronologia Chat - {phoneNumber}</h2>
                </div>
                {isSearching ? (
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border rounded px-2 py-1"
                        placeholder="Cerca messaggi..."
                    />
                ) : (
                    <button onClick={() => setIsSearching(true)}>
                        <SearchIcon className="h-6 w-6 text-gray-600" />
                    </button>
                )}
            </div>

            {/* Chat messages */}
            <div
                className="flex-1 overflow-y-auto p-4 space-y-2"
                style={{
                    backgroundImage: `url("/whatsapp-background.png")`,
                    backgroundSize: "contain",
                    backgroundRepeat: 'repeat',
                }}
            >
                {filteredMessages.map((message) => (
                    <div
                        key={message.id}
                        className={`flex ${
                            message.isFromCustomer ? 'justify-start' : 'justify-end'
                        }`}
                    >
                        <div
                            className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                                message.isFromCustomer
                                    ? 'bg-white text-black'
                                    : 'bg-[#d9fdd3] text-black'
                            }`}
                        >
                            <p>{message.content}</p>
                            <div className="text-xs text-gray-500 float-right mt-1">
                                {formatDate(message.timestamp)}
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

export default ChatHistoryInterface;