import React, { useState, useEffect } from 'react';
import {getTemplates, sendMessage} from '../services/api';
import { SearchIcon, DocumentTextIcon } from '@heroicons/react/solid';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TemplateSelector = ({ onSelectTemplate, phoneNumber, onBack }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const socialProfileId = '2'; // Sostituisci con l'ID effettivo o passa come prop
                const data = await getTemplates(socialProfileId);
                setTemplates(data);
                setLoading(false);
            } catch (err) {
                toast.error('Errore nel caricamento dei template');
                setError('Errore nel caricamento dei template');
                setLoading(false);
            }
        };

        fetchTemplates();
    }, []);

    const filteredTemplates = templates.filter(template =>
        template.elementName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleTemplateSelect = async (template) => {
        try {
            // Estrai i parametri dall'URL
            const urlParams = new URLSearchParams(window.location.search);
            const name = urlParams.get('name');
            const surname = urlParams.get('surname');

            // Verifica se i parametri esistono
            const customer = {
                firstName: name || '',
                lastName: surname || ''
            };
            const templateParams = [
                customer.firstName,  // Primo parametro: Nome del cliente
                customer.lastName    // Secondo parametro: Cognome del cliente
            ];

            await sendMessage({
                destination: phoneNumber,
                accountNumber: '2', // Assicurati che questo sia il valore corretto
                template: template.id,
                templateParams: templateParams,
                content: template.elementName // O qualsiasi altro contenuto appropriato
            });

            onSelectTemplate(template);
            toast.success('Template inviato con successo!');
        } catch (error) {
            // Log error details
            console.error('Errore nell\'invio del messaggio template:', error);

            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;

                // Gestione per array di errori
                if (Array.isArray(errors)) {
                    toast.error('Errore nell\'invio del messaggio all\'utente: ' + errors.join(', '));
                }
                // Gestione per stringhe o oggetti
                else if (typeof errors === 'string') {
                    toast.error('Errore nell\'invio del messaggio all\'utente: ' + errors);
                } else if (typeof errors === 'object') {
                    const errorMessages = Object.values(errors).flat().join(', ');
                    toast.error('Errore nell\'invio del messaggio all\'utente: ' + errorMessages);
                } else {
                    toast.error('Errore sconosciuto nell\'invio del messaggio.');
                }
            }
            // Gestione di altri errori (es. problemi di rete)
            else if (error.message) {
                toast.error('Errore: ' + error.message);
            } else {
                toast.error('Si è verificato un errore sconosciuto. Riprova.');
            }
        }
    };

    if (loading) return (
        <div className="flex justify-center items-center h-full bg-[#f0f2f5]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#25d366]"></div>
        </div>
    );

    if (error) return (
        <div className="text-red-500 text-center py-4 bg-[#f0f2f5]">
            <p>{error}</p>
            <button
                onClick={() => window.location.reload()}
                className="mt-2 bg-[#25d366] hover:bg-[#128c7e] text-white font-bold py-2 px-4 rounded"
            >
                Riprova
            </button>
        </div>
    );

    return (
        <div className="flex flex-col h-full bg-[#f0f2f5]">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {/* Header */}
            <div className="bg-[#075e54] text-white py-2 px-4 flex items-center">
                <h1 className="text-xl font-semibold">Seleziona Template</h1>
            </div>

            {/* Search bar */}
            <div className="bg-[#f0f2f5] p-2">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Cerca template..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-[#25d366]"
                    />
                    <SearchIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
            </div>

            {/* Phone number display */}
            {phoneNumber && (
                <div className="bg-white px-4 py-2 border-b border-gray-200">
                    <p className="text-sm text-gray-600">Numero di telefono: {phoneNumber}</p>
                </div>
            )}

            {/* Template list */}
            <div className="flex-1 overflow-y-auto">
                {filteredTemplates.map((template) => (
                    <button
                        key={template.id}
                        onClick={() => handleTemplateSelect(template)}
                        className="w-full text-left px-4 py-3 border-b border-gray-200 hover:bg-gray-100 transition duration-150 ease-in-out"
                    >
                        <div className="flex items-center">
                            <div className="bg-[#25d366] rounded-full p-2 mr-3">
                                <DocumentTextIcon className="h-6 w-6 text-white" />
                            </div>
                            <div>
                                <h3 className="font-semibold text-gray-800">{template.elementName}</h3>
                                <p className="text-sm text-gray-600 truncate">Tocca per selezionare questo template</p>
                            </div>
                        </div>
                    </button>
                ))}
                {filteredTemplates.length === 0 && (
                    <div className="text-center text-gray-500 mt-4 p-4">
                        Nessun template trovato
                    </div>
                )}
            </div>
        </div>
    );
};

export default TemplateSelector;