import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Header from "./components/Header";
import TemplateSelector from "./components/TemplateSelector";
import ChatInterface from "./components/ChatInterface";
import CreateTemplateModal from "./components/CreateTemplateModal";
import {archiveChat, getChatById, getChatHistory} from './services/api';
import {initializeSignalRConnection} from "./services/signalRService";
import {toast} from "react-toastify";
import ChatSelectorModal from "./components/ChatSelectorModal";

function App() {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] = useState(false);
    const [chatHistory, setChatHistory] = useState(null);
    const [showChat, setShowChat] = useState(false);
    const [canSendMessages, setCanSendMessages] = useState(true);
    const { phoneNumber } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isChatSelectorOpen, setIsChatSelectorOpen] = useState(false);
    const [selectedReadOnlyChat, setSelectedReadOnlyChat] = useState(null);
    const { chatId } = useParams();

    const ChatTimeoutModal = ({ onReadOnly, onNewChat }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl mb-4">La chat è stata inattiva per più di 24 ore</h2>
                <p className="mb-4">Cosa vorresti fare?</p>
                <div className="flex justify-around">
                    <button
                        onClick={onReadOnly}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Visualizza in sola lettura
                    </button>
                    <button
                        onClick={onNewChat}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                        Inizia nuova conversazione
                    </button>
                </div>
            </div>
        </div>
    );
    useEffect(() => {
        initializeSignalRConnection().catch(console.error);
    }, []);

    useEffect(() => {
        const loadChatHistory = async () => {
            if (!phoneNumber) {
                console.log("Non è presente il numero di telefono nell'indirizzo")
                toast.error("Non è presente il numero di telefono nell'indirizzo")
                return;
            }

            if (location.pathname.startsWith('/templates')) {
                setShowChat(false);
                return;
            }

            try {
                const history = await getChatHistory(phoneNumber);
                setChatHistory(history);

                if (history.length > 0) {
                    const lastUserMessage = history.filter(msg => msg.isFromCustomer).pop();

                    if (lastUserMessage) {
                        const currentTime = new Date();
                        const lastMessageTime = new Date(lastUserMessage.timestamp);
                        const hoursSinceLastMessage = (currentTime - lastMessageTime) / (1000 * 60 * 60);

                        if (hoursSinceLastMessage <= 24) {
                            setShowChat(true);
                            setSelectedTemplate({ id: 'history', name: 'Chat History' });
                            setCanSendMessages(true);
                        } else {
                            //await archiveChat(phoneNumber);
                            setShowTimeoutModal(true);
                        }
                    } else {
                        setShowChat(true);
                        setCanSendMessages(false);
                    }
                } else {
                    toast.warning('Tutte le chat risultano archiviate indirizzamento alla nuova chat')
                    navigate(`/templates/${phoneNumber}`);
                }
            } catch (error) {
                console.error('Errore nel caricamento della cronologia:', error);
                toast.error("Non è presente il numero di telefono nell'indirizzo")
                //navigate(`/templates/${phoneNumber}`);
            }
        };

        loadChatHistory();
    }, [phoneNumber, navigate, location]);

    const handleReadOnly = async () => {
        const history = await getChatHistory(phoneNumber);
        setChatHistory(history);
        setIsReadOnly(true);
        setShowTimeoutModal(false);
        setSelectedTemplate({id: 'history', name: 'Chat History'});
        setShowChat(true);
        setCanSendMessages(false);
    };
    const handleNewChat = () => {
        setShowTimeoutModal(false);
        navigate(`/templates/${phoneNumber}`);
    };

    const handleCreateTemplate = () => {
        setIsCreateTemplateModalOpen(true);
    };

    const handleBack = () => {
        setSelectedTemplate(null);
        setShowChat(false);
        navigate('/templates');
    };

    const handleSelectTemplate = (template) => {
        setSelectedTemplate(template);
        setShowChat(true);
        setCanSendMessages(true);
        if (phoneNumber) {
            navigate(`/chat/${phoneNumber}`);
        }
    };

    const handleViewAllChats = () => {
        setIsChatSelectorOpen(true);
    };

    const handleSelectChat = async (chat) => {
        setIsChatSelectorOpen(false);
        setSelectedReadOnlyChat(chat);
        setIsReadOnly(true);
        setShowChat(true);
        setCanSendMessages(false);

        try {
            const chatData = await getChatById(chat.id);
            setChatHistory(chatData.messages);
            navigate(`/chat/${chat.id}`); // Modifica qui: usa l'ID della chat invece del numero di telefono
        } catch (error) {
            console.error('Errore nel caricamento della chat:', error);
            toast.error('Errore nel caricamento della chat');
        }
    };

    useEffect(() => {
        const loadChat = async () => {
            if (chatId) {
                try {
                    const chatData = await getChatById(chatId);
                    setChatHistory(chatData.messages);
                    setShowChat(true);
                    setIsReadOnly(true);
                    setCanSendMessages(false);
                } catch (error) {
                    console.error('Errore nel caricamento della chat:', error);
                    toast.error('Errore nel caricamento della chat');
                }
            }
        };

        loadChat();
    }, [chatId]);

    return (
        <div className="h-screen flex flex-col bg-gray-100">
            {(!showChat && !selectedTemplate) && (
                <Header
                    onCreateTemplate={handleCreateTemplate}
                    onViewAllChats={handleViewAllChats}
                    phoneNumber={phoneNumber}
                />
            )}
            <div className="flex-1 overflow-hidden">
                {showTimeoutModal && (
                    <ChatTimeoutModal
                        onReadOnly={handleReadOnly}
                        onNewChat={handleNewChat}
                    />
                )}
                {showChat ? (
                    <ChatInterface
                        selectedTemplate={selectedTemplate}
                        onBack={handleBack}
                        initialMessages={chatHistory || []}
                        phoneNumber={phoneNumber || (selectedReadOnlyChat && selectedReadOnlyChat.phoneNumber)}
                        canSendMessages={canSendMessages}
                        isReadOnly={isReadOnly}
                        chatId={chatId} // Passa l'ID della chat
                    />
                ) : (
                    <TemplateSelector onSelectTemplate={handleSelectTemplate} phoneNumber={phoneNumber} />
                )}
            </div>
            <CreateTemplateModal
                isOpen={isCreateTemplateModalOpen}
                onClose={() => setIsCreateTemplateModalOpen(false)}
            />
            <ChatSelectorModal
                isOpen={isChatSelectorOpen}
                onClose={() => setIsChatSelectorOpen(false)}
                onSelectChat={handleSelectChat}
                phoneNumber={phoneNumber}
            />
        </div>
    );
}

export default App;