import React, { useState } from 'react';
import { getAllChats, getChatById } from '../services/api';
import { toast } from "react-toastify";
import ChatInterface from './ChatInterface';
import ChatHistory from "./ChatHistory";
import {useNavigate} from "react-router-dom"; // Assicurati che il percorso sia corretto

const ChatSelectorModal = ({ isOpen, onClose, phoneNumber }) => {
    const [inputPhoneNumber, setInputPhoneNumber] = useState(phoneNumber || '');
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const navigate = useNavigate();

    const fetchChats = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getAllChats(inputPhoneNumber);
            if (!data || data.length === 0) {
                toast.error("Non è stata trovata una cronologia chat per questo numero");
                setChats([]);
            } else {
                setChats(data);
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
            setError('Impossibile caricare le chat. Riprova più tardi.');
            toast.error('Errore nel caricamento delle chat');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectChat = (chat) => {
        if (chat && chat.id) {
            navigate(`/chat-history/${chat.id}?chatId=${chat.id}`);
            onClose();
        } else {
            console.error('Chat or chat.id is undefined');
            toast.error('Errore: ID della chat non valido');
        }
    };


    /*const handleSelectChat = async (chat) => {
        try {
            if (chat && chat.id) {
                const chatData = await getChatById(chat.id);
                setChatHistory(chatData.messages);
                setSelectedChat(chat);
            } else {
                console.error('Chat or chat.id is undefined');
                toast.error('Errore: ID della chat non valido');
            }
        } catch (error) {
            console.error('Errore nel caricamento della chat:', error);
            toast.error('Errore nel caricamento della chat');
        }
    };*/

    const handleBack = () => {
        setSelectedChat(null);
        setChatHistory([]);
    };

    if (!isOpen) return null;

    if (selectedChat) {
        return (
            <ChatHistory
                selectedTemplate={{ id: 'history', name: 'Chat History' }}
                onBack={handleBack}
                initialMessages={chatHistory}
                phoneNumber={selectedChat.phoneNumber}
                canSendMessages={false}
                isReadOnly={true}
                chatId={selectedChat.id}
            />
        );
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
                <h2 className="text-xl mb-4">Seleziona una chat</h2>
                {!phoneNumber && (
                    <input
                        type="text"
                        value={inputPhoneNumber}
                        onChange={(e) => setInputPhoneNumber(e.target.value)}
                        placeholder="Inserisci il numero di telefono"
                        className="w-full p-2 border rounded mb-4"
                    />
                )}
                <button
                    onClick={fetchChats}
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                    disabled={loading}
                >
                    {loading ? 'Caricamento...' : 'Carica chat'}
                </button>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <div className="max-h-60 overflow-y-auto">
                    {chats.map((chat) => (
                        <div
                            key={chat.id}
                            onClick={() => handleSelectChat(chat)}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                        >
                            Chat del {new Date(chat.lastCustomerMessageTime).toLocaleString()}
                        </div>
                    ))}
                </div>
                <button
                    onClick={onClose}
                    className="mt-4 bg-gray-300 text-black px-4 py-2 rounded"
                >
                    Chiudi
                </button>
            </div>
        </div>
    );
};

export default ChatSelectorModal;